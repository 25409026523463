import React, { useMemo } from 'react';
import {
  Grid, Box, Button, Alert,
} from '@mui/material';
import StyledBox from 'views/common/components/UI/CheckInDetails/CheckInDetails.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { ImgBox } from 'views/common/components';

const CheckInDetails = (props) => {
  const { t } = useTranslation('fe_er_common_check_in_details');
  const { checkinPath } = props;
  const checkInItems = useMemo(
    () => [
      {
        iconSrc: '/imgs/CheckinDetails/validate.png',
        /* Add completed icon when state is completed */
        // icon: <DriverLicenseCompleted />,
        text: t('fe_er_common_check_in_details:validate_your_driver_license'),
      },
      {
        iconSrc: '/imgs/CheckinDetails/confirm.png',
        /* Add completed icon when state is completed */
        // icon: <ConfirmInformationCompleted />,
        text: t('fe_er_common_check_in_details:confirm_your_information'),
      },
      {
        iconSrc: '/imgs/CheckinDetails/watch.png',
        /* Add completed icon when state is completed */
        // icon: <OrientationVideoCompleted/>
        text: t('fe_er_common_check_in_details:watch_your_orientation_video'),
      },
    ],
    [],
  );
  return (
    <StyledBox>
      <Grid container spacing={3}>
        {checkInItems.map((checkInItem) => (
          <Grid item lg={4} md={4} sm={12} key={checkInItem.id}>
            <Box display="flex" alignItems="center">
              <Box mr={3} className="CDIcon">
                <ImgBox
                  image={{
                    src: checkInItem.iconSrc,
                    alt: 'icon',
                  }}
                  imgWidth="60px"
                  imgHeight="60px"
                />
              </Box>
              <Typography500 variant="h5">{checkInItem.text}</Typography500>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box mt={4}>
        <Button
          variant="outlined"
          color="secondary"
          className="CDBtn"
          component={NavigatorLink}
          href={checkinPath}>
          {t('fe_er_common_check_in_details:start_check-in')}
        </Button>
      </Box>
      <Box mt={4}>
        <Alert severity="warning" className="CDAlert">
          {t(
            'fe_er_common_check_in_details:check_in_is_required_before_trips_starts',
          )}
        </Alert>
      </Box>
    </StyledBox>
  );
};

export { CheckInDetails };
