import { Box, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.AOCadventuresCard': {
    position: 'relative',
    height: theme.spacing(54.125),
    width: '100%',
    color: theme.palette.common.fogWhite,
    transition: 'height ease .2s',
    '& .AOCoverlay': {
      position: 'absolute',
      left: 0,
      top: '50%',
      width: '100%',
      height: '50%',
      zIndex: 0,
      background: `linear-gradient(to bottom, transparent,${theme.palette.grey[900]})`,
    },
    '& svg.AOCplayIcon': {
      width: theme.spacing(8.625),
      height: theme.spacing(8.625),
      marginBottom: theme.spacing(8.4),

      position: 'absolute',
      left: '50%',
      right: '50%',
      transform: 'translateX(-50%)',
      top: '40%',
      opacity: 0,
      transition: 'opacity ease .2s',
      '&.deActiveUnHover': {
        opacity: 1,
      },
      '&.activeAndHover': {
        opacity: 0,
      },
    },
    '& .AOCdetailsContainer': {
      textAlign: 'center',
      position: 'absolute',
      left: theme.spacing(3),
      right: theme.spacing(3),
      bottom: theme.spacing(2),
      zIndex: 1,
      '& .AOCwatchNow': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1.5),
        opacity: 0,
        transition: 'opacity ease .2s',
        '& svg': {
          width: theme.spacing(3),
          height: theme.spacing(3),
          marginRight: theme.spacing(1),
        },
      },
      '& .AOCDetailsTitle': {
        wordBreak: 'break-word',
        fontSize: theme.typography.pxToRem(20),
      },
    },
    '&:hover': {
      cursor: 'pointer',
      // height: theme.spacing(53.5),
      '& .AOCwatchNow': {
        opacity: 1,
      },
      '& .AOCplayIcon.deActiveUnHover': {
        opacity: 0,
      },
      '& .AOCplayIcon.activeAndHover': {
        opacity: 1,
      },
    },
    '&.active': {
      // height: theme.spacing(53.5),
      border: `3px solid ${alpha(theme.palette.primary.main, 0.64)}`,
      '& .AOCwatchNow': {
        opacity: 0,
      },
      /// ///////////////////// hide tringle couse of replace camera caught video with overlay
      // '& .AOCtriangle': {
      //   position: 'absolute',
      //   bottom: theme.spacing(-2.5),
      //   left: '50%',
      //   right: '50%',
      //   transform: 'translateX(-50%)',
      //   borderTop: `solid ${theme.spacing(2.5)} ${theme.palette.primary.main}`,
      //   borderLeft: `solid ${theme.spacing(2)} transparent`,
      //   borderRight: `solid ${theme.spacing(2)} transparent`,
      //   transition: 'all ease .2s',
      //   opacity: 0.64,
      // },
      // '& .AOCplayIcon.deActiveUnHover, .AOCplayIcon.activeAndHover ': {
      //   opacity: 0,
      // },
    },
    '& .IBimg': {
      objectPosition: 'top',
    },
    '&.AOCOverlayCard': {
      '&.active': {
        border: 0,
      },
      '&.AOCadventuresCard': {
        height: theme.spacing(30),
      },
      '& .AOCwatchNow': {
        display: 'none',
      },
      '& .AOCdetailsContainer': {
        bottom: theme.spacing(2),
      },
      '& svg.AOCplayIcon': {
        width: theme.spacing(6),
        height: theme.spacing(6),
      },
    },
  },
}));
export default StyledBox;
