import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EagleRiderClubGrayscale = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none">
    <path
      d="M2.0533 11.9864L9.66473 16.3703L17.2762 11.9864V1.66878H2.0533V11.9864ZM9.66473 17.9734L0.666504 12.7774V0.281982H18.6665V12.7774L9.66473 17.9734Z"
      fill="#777777"
    />
    <path
      d="M6.77082 6.36109C6.06147 6.26888 6.11467 6.04898 6.11467 6.04898L8.00866 5.72267C8.00866 5.72267 7.03683 6.39656 6.77082 6.36109ZM16.1982 9.55321C14.7015 9.02474 12.4883 7.33646 12.4883 7.33646C12.9327 7.33734 13.3768 7.31247 13.8183 7.26198C13.0815 6.92524 12.3703 6.53524 11.6902 6.09508C10.7823 5.45311 10.5624 4.9672 9.24649 4.8076C9.02079 4.77605 8.79337 4.75828 8.56551 4.75439C7.52345 4.76143 6.5178 5.13855 5.72807 5.81843C5.49752 6.01705 5.34147 6.13055 5.34147 6.13055C4.81664 6.16341 4.29935 6.27212 3.8057 6.45331C3.67661 6.48745 3.55647 6.54921 3.45359 6.63433C3.35071 6.71945 3.26754 6.8259 3.20984 6.94632C3.04669 7.28326 3.2595 8.16287 3.26659 8.15577C4.62501 7.31873 7.04038 8.15577 7.20353 8.46789C6.97654 7.86494 6.05437 7.24425 6.05437 7.24425C6.05437 7.24425 7.47309 7.56701 7.82777 8.82257C8.18245 10.0781 6.54738 10.6314 6.45161 10.6811C7.24622 10.5838 8.03397 10.4369 8.81023 10.2413C8.79907 10.4219 8.77177 10.6011 8.72866 10.7769C9.08334 10.6066 9.88846 10.2129 9.88846 10.2129C9.88846 10.2129 11.9527 10.6031 12.7578 10.6634C12.3085 10.3916 11.9021 10.0545 11.5519 9.66316C11.5519 9.66316 14.0737 10.2236 14.9214 10.3725C14.4958 10.0912 14.1145 9.74814 13.7899 9.35459C14.5814 9.49361 15.3841 9.55774 16.1876 9.54612"
      fill="#777777"
    />
  </SvgIcon>
);

export default EagleRiderClubGrayscale;
