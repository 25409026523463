export const lightStyle = [
  {
    elementType: 'geometry',
    stylers: [{ hue: '#EAEAD3' }, { lightness: 30 }],
  },

  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },

  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#75CFF0',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];
