import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.common.fogWhite,

  width: '100%',

  '& .AAdownloadBtns': {
    '& .MuiLink-root': {
      display: 'inline-block',
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('xl')]: {
      height: theme.spacing(4.3),
      width: theme.spacing(15),
    },
  },
}));

export default StyledBox;
