import React from 'react';
import GoogleMap from 'google-map-react';
import { darkStyle } from 'views/common/components/UI/Map/utils/darkStyle';
import { lightStyle } from 'views/common/components/UI/Map/utils/lightStyle';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const Map = (props) => {
  const {
    defaultZoom,
    defaultCenter,
    mapZoom,
    mapCenter,
    handleMarkerClick,
    handleMapOnChange,
    renderMapMarkers = () => {},
    darkMode = false,
    ...restOfProps
  } = props;
  const mapOptions = {
    fullscreenControl: false,
    gestureHandling: 'greedy',
    styles: darkMode ? darkStyle : lightStyle,
  };
  return (
    <GoogleMap
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{ key: envVars.GOOGLE_MAPS_API_KEY }}
      zoom={mapZoom}
      defaultZoom={defaultZoom}
      center={mapCenter}
      onChildClick={handleMarkerClick}
      defaultCenter={defaultCenter}
      onChange={handleMapOnChange}
      {...restOfProps}
      options={mapOptions}>
      {renderMapMarkers()}
    </GoogleMap>
  );
};
export { Map };
