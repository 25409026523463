import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .RCWrapper': {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  '& .RCTitle': {
    color: theme.palette.secondary.light,
  },
  '& .RCTextField': {
    minWidth: theme.spacing(40),
    marginRight: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(5),
    },
    '& input': {
      fontSize: theme.typography.pxToRem(20),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'dashed',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  '& .RCButton': {
    color: theme.palette.common.fogWhite,
    height: theme.spacing(5),
  },
}));

export default StyledBox;
