import { Box } from '@mui/material';
import Carousel from 'react-multi-carousel';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme }) => ({
  '&.MACOCmotorcycleAdventures': {
    '& .MACOCmaintitle': {
      marginBottom: theme.spacing(4),
      '& .MACOCitemNumbers': {
        marginLeft: theme.spacing(2),
        color: theme.palette.grey[300],
        fontWeight: 300,
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
    '& .MACCarouselWrapper': {
      position: 'relative',
    },
    '&.MACOCRemoveContainer': {
      '& .MuiContainer-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}));

export const StyledCarousel = styled(Carousel)(({ theme, ssr }) => ({
  '&.react-multi-carousel-list': {
    position: 'static',
    paddingBottom: theme.spacing(3.75),
    '& .react-multi-carousel-item': {
      '& .CarouselItem': {
        margin: theme.spacing(0, 0.75),
        overflow: 'visible',
      },
      '&:first-child': {
        '& .CarouselItem': {
          margin: theme.spacing(0, 1.5, 0, 0),
        },
      },
      '&:last-child': {
        '& .CarouselItem': {
          margin: theme.spacing(0, 0, 0, 1.5),
        },
      },
    },
  },
  '& .arrow': {
    position: 'absolute',
    top: '40%',
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: '50%',
    backgroundColor: 'rgba(48, 48, 48, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.fogWhite,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },

    '&.arrowLeft': {
      left: 'calc(4% + 1px)',
      [theme.breakpoints.up('lg')]: {
        left: theme.spacing(-2),
      },
    },

    '&.arrowRight': {
      right: 'calc(4% + 1px)',
      [theme.breakpoints.up('lg')]: {
        right: theme.spacing(-2),
      },
    },

    '& > svg': {
      fontSize: theme.typography.pxToRem(32),
      transition: ssr && 'none',
    },
  },
  '& .react-multiple-carousel__arrow': {
    transition: ssr && 'none',
  },
}));
