import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PaymentsOptions from 'views/common/components/Logical/PaymentsOptions';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .MOSTSecPadd': {
    padding: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
      paddingBottom: '0',
    },
  },
  '& .MOSTAside': {
    backgroundColor: theme.palette.grey.A000,
    [theme.breakpoints.down('lg')]: {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  '& .MOReferralCode': {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4.5),
      marginBottom: theme.spacing(4.5),
    },
  },
  '& .MODivider': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '0',
  },
  '& label': {
    color: theme.palette.grey[400],
  },

  '& .MuiFilledInput-root': {
    '&, &:hover': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[200],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: theme.spacing(0),
      '&:before': {
        borderBottom: 'none !important',
      },
      '&:after': {
        transform: 'none',
        opacity: '0',
        transition: 'opacity .4s',
      },
      '&.Mui-focused ': {
        backgroundColor: theme.palette.common.white,
        '&:after': {
          opacity: '1',
          boxShadow: 'rgb(93, 93, 93) 0px 1px 0px 0px',
          borderBottom: '1px solid rgb(93, 93, 93)',
        },
      },
      '&.Mui-disabled': {
        opacity: '80%',
        borderStyle: 'dashed',
        borderBottomStyle: 'dotted',
        cursor: 'not-allowed',
        backgroundColor: 'rgba(0,0,0,0.05)',
        '&:after': {
          opacity: '1',
          boxShadow: 'none',
          borderBottom: 'none',
        },
        '& input': {
          cursor: 'not-allowed',
        },
      },
      '&.Mui-error': {
        '&:after': {
          opacity: '1',
          boxShadow: '#f44336 0px 1px 0px 0px',
          borderBottom: '1px solid #f44336',
        },
      },
    },
  },
}));

export const StyledPaymentsOptions = styled(PaymentsOptions)(({ theme }) => ({
  '&.styledPaymentsOptions': {
    '& .PaymentOption-root,& .PaymentOption-title,& .PaymentOption-body': {
      border: 0,
      padding: 0,
      backgroundColor: 'transparent',
    },
    '& .PaymentOption-body': {
      '& .PaymentOption-information': {
        marginBottom: theme.spacing(3),
      },
      '& .CreditCardList-root': {
        '&:first-child': {
          marginTop: theme.spacing(2),
        },
        '& .CreditCardList-labelOption': {
          width: '100%',
          border: 0,
          backgroundColor: theme.palette.grey.A000,
          marginTop: 0,

          '&.CreditCardList-active': {
            backgroundColor: theme.palette.grey[600],
          },
        },
      },
    },
    '& .PaymentOption-footer': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .PaymentOption-title': {
      '& .MuiTypography-root': {
        fontWeight: '500',
        fontSize: theme.typography.pxToRem(21),
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
          paddingTop: theme.spacing(2),
        },
      },
    },

    '& .CreditCardForm-root': {
      marginBottom: theme.spacing(4),
      '& .MuiTypography-root': {
        fontWeight: '500',
        fontSize: theme.typography.pxToRem(21),
      },
    },
  },
}));

export default StyledBox;
