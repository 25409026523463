import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { localeDateFormatMap, localeDateMaskMap } from 'lib/config/date-picker';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';
import deLocale from 'dayjs/locale/de';
import frLocale from 'dayjs/locale/fr';
import esLocale from 'dayjs/locale/es';
import itLocale from 'dayjs/locale/it';
import ptLocale from 'dayjs/locale/pt';

const mapNamedLocaleToDayJsLocale = {
  'motorrad-mieten': deLocale,
  'location-moto': frLocale,
  'alquiler-de-motos': esLocale,
  'noleggio-moto': itLocale,
  'aluguel-de-motos': ptLocale,
};

const LocalizedDatePicker = (props) => {
  const appNavigator = useAppNavigator();
  useEffect(() => {
    dayjs.locale(mapNamedLocaleToLocale[appNavigator.locale].toLowerCase());
    return () => {
      dayjs.locale(mapNamedLocaleToLocale.en.toLowerCase());
    };
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={DateAdapter}
      adapterLocale={mapNamedLocaleToDayJsLocale[appNavigator.locale]}>
      <DatePicker
        format={localeDateFormatMap[appNavigator.locale]}
        mask={localeDateMaskMap[appNavigator.locale]}
        {...props}
      />
    </LocalizationProvider>
  );
};

export { LocalizedDatePicker };
