import React from 'react';
import {
  Stack,
  Box,
  Typography,
  Button,
  Alert,
  Divider,
  IconButton,
} from '@mui/material';
import StyledBox, {
  StyledImgBox,
} from 'views/common/components/UI/ReservationInformation/ReservationInformation.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import ErrorIcon from '@mui/icons-material/Error';
import parseZone from 'lib/utils/parseZone';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { TOUR_TYPES } from 'lib/utils/CONSTANTS';

const ReservationInformation = (props) => {
  const {
    tourInfo,
    reservationIdentifier,
    tripName,
    bookedDate,
    image,
    vehicleReservationActions,
  } = props;
  const { t } = useTranslation('fe_er_common_reservation_info');

  return (
    <StyledBox>
      <Stack className="RIContent">
        <StyledImgBox
          image={{
            src: image.src,
            alt: image.alt,
          }}
          imgWidth="18.75rem"
          imgHeight="12.75rem"
          imgObjectFit="contain"
        />
        <Box className="RITextInfo">
          {tourInfo ? (
            <Box>
              <Typography500 variant="h4">
                <NavigatorLink
                  href={tourInfo.tourUrl}
                  target="_blank"
                  className="RITourNameLink">
                  {tourInfo.name}
                </NavigatorLink>
              </Typography500>
              <Typography variant="body2" component="P">
                {tourInfo.tour_type.identifier !== TOUR_TYPES.GUIDED_OFFROAD
                  ? t(
                    'fe_er_common_reservation_info:numbers_of_days_and_nights',
                    {
                      days_count: tourInfo.days_count,
                      nights_count: tourInfo.days_count - 1,
                    },
                  )
                  : tourInfo.tour_type.name}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography500 variant="h4">{tripName}</Typography500>
            </Box>
          )}

          <Box my={2} className="RISecondaryColor">
            <Box>
              <Typography500 variant="h6" component="h6">
                {t('fe_er_common_reservation_info:reservation_number')}
                {' '}
                <Typography500
                  variant="h6"
                  component="span"
                  className="RIRservNum">
                  {reservationIdentifier}
                  <IconButton
                    onClick={
                      vehicleReservationActions.copyReservationNumberAction
                    }>
                    <ContentCopyRoundedIcon className="RICopyIcon" />
                  </IconButton>
                </Typography500>
              </Typography500>
            </Box>

            <Box className="RIBookedOn">
              <Typography500 variant="h6" component="p">
                {t('fe_er_common_reservation_info:booked_on')}
              </Typography500>
              <Typography500 variant="h6" component="p" pl={0.5}>
                {bookedDate
                  ? parseZone(bookedDate).format('ddd, MMM DD, YYYY')
                  : null}
              </Typography500>
            </Box>
          </Box>
          <Box mb={2} className="showMOBILE">
            <Divider light />
          </Box>
          <Box>
            {vehicleReservationActions.can_continue && (
              <Button
                variant="contained"
                color="primary"
                className="RIContinueButton RIFullWidth"
                onClick={vehicleReservationActions.continueReservationAction}>
                {t('fe_er_common_reservation_info:continue_booking')}
              </Button>
            )}
            {vehicleReservationActions.can_edit && (
              <Button
                variant="contained"
                className="RIFullWidth"
                color="primary"
                onClick={vehicleReservationActions.makeChangeAction}>
                {t('fe_er_common_reservation_info:make_change')}
              </Button>
            )}
            {vehicleReservationActions.can_cancel && (
              <Button
                variant="text"
                color="secondary"
                className="RICancelButton RIFullWidth"
                onClick={vehicleReservationActions.cancelAction}>
                {t('fe_er_common_reservation_info:cancel_booking')}
              </Button>
            )}
          </Box>
        </Box>
      </Stack>
      {vehicleReservationActions.showTimedOutPaymentsAlert && (
        <Box mt={3} className="RIAlertWrapper">
          <Alert
            severity="error"
            className="RIErrorAlert"
            iconMapping={{ error: <ErrorIcon /> }}>
            {t('fe_er_common_reservation_info:your_reservation_not_confirmed')}
          </Alert>
        </Box>
      )}
    </StyledBox>
  );
};

export { ReservationInformation };
