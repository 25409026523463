import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChatBotWrapper } from 'views/common/components/Logical/ChatBotWrapper/ChatBotWrapper';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .CBMDCloseBtn': {
    color: theme.palette.common.fogWhite,
    position: 'absolute',
    padding: theme.spacing(2.5, 2),
    zIndex: 10,
    right: 0,
  },
}));

export const StyledChatBotWrapper = styled(ChatBotWrapper)(({ theme }) => ({
  '& .CVBody': {
    top: theme.spacing(8),
  },
}));

export default StyledDialog;
