import React, { useEffect, useState } from 'react';
import { styled, useTheme, keyframes } from '@mui/material/styles';
import { Dialog, DialogContent, Box } from '@mui/material';
import { MainWidgetDialogTitle } from 'views/common/components/UI/MainWidgetDialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MainWidgetWrapper } from 'views/common/components/Logical/MainWidgetWrapper';

/* const center = keyframes`
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);

  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
`; */
const floatingWidgetContent = keyframes`
  0% {
    transform:translateY(-100%) scaleX(1);
    opacity: 0;
  }
  50% {
    transform:translateY(-100%) scaleX(1);
    opacity: 0;
  }
  90%{
    transform:translateY(5%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const floatingWidgetContainer = keyframes`
  0% {
   transform : scaleY(0) scaleX(0);
  }
  20% {
    transform : scaleY(.2) scaleX(1);

  }
  50% {
    transform : scaleY(1);

  },
`;
const floatingWidgetContainerMobile = keyframes`
  0% {
   transform : scaleY(0) scaleX(0);
  }
  50% {
    transform : scaleY(1) scaleX(1);
  }
`;

const StyledDialog = styled(Dialog)(({ theme, scrolling, bikeName }) => ({
  top: scrolling ? theme.spacing(4) : 0,
  right: scrolling ? theme.spacing(8) : 0,
  bottom: 'unset',
  left: 'unset',
  position: scrolling ? 'fixed' : 'absolute',
  transition: 'top, right .3s',
  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  '& .MuiBackdrop-root': {
    backgroundColor: `${theme.palette.common.fogWhite}fff90`,
  },
  '& .MuiDialog-paper': {
    all: 'initial',
    maxWidth: theme.typography.pxToRem(820),
    width: theme.typography.pxToRem(820),
    margin: 0,
    height: 'auto',
    overflow: 'hidden',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
      width: '100%',
      height: '100%',
    },
  },
  '& .MWDWidgetContainer': {
    overflow: 'hidden',
    border: `2px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    height: '100%',
    backgroundColor: 'white',
    animation: `${floatingWidgetContainer} 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000)`,
    transformOrigin: '100% 0%',
    [theme.breakpoints.down('lg')]: {
      border: 'none',
      borderRadius: 0,
      transformOrigin: '100% 100%',
      animation: `${floatingWidgetContainerMobile} 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000)`,
      overflowY: 'auto',
    },
  },
  '& .MuiDialogContent-root': {
    transformOrigin: '100% 0%',
    animation: `${floatingWidgetContent} 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000)`,
    overflow: bikeName && 'hidden',
    paddingTop: bikeName && 0,
  },
}));

const StyledMainWidgetWrapper = styled(MainWidgetWrapper)(({ theme }) => ({
  '& .WTButton': {
    color: `${theme.palette.grey[800]} !important`,
    '& .WTTitle': {
      fontSize: theme.typography.pxToRem(22),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  },
}));

const MainWidgetDialog = (props) => {
  const {
    scrollValue,
    fixPosition,
    floatingDialog,
    handleClose,
    open,
    image,
    bikeName,
    bikeDesc,
    container,
    className,
    ...restofProps
  } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [scroll, setScroll] = useState(false);
  // this state is used to prevent rendering the dialog on the first render
  // on the first render isTablet is always false so that there's no hydration mismatch
  // this is because disableScrollLock apparantly get locked on its first render value
  const [shouldRenderDialog, setShouldRenderDialog] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > scrollValue;
      setScroll(scrollCheck);
    };
    document.addEventListener('scroll', onScroll);
    setShouldRenderDialog(true);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <>
      {shouldRenderDialog && (
        <StyledDialog
          container={container}
          scrolling={scroll || fixPosition}
          disableScrollLock={!isTablet}
          onClose={handleClose}
          open={open}
          bikeName={bikeName}
          fullScreen={isTablet}
          className={`MainWidgetDialog ${
            floatingDialog && 'floatingDialog'
          } ${className}`}
          floatingDialog={floatingDialog}>
          <Box className="MWDWidgetContainer">
            <MainWidgetDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
              floatingDialog={floatingDialog}
              image={image}
              bikeName={bikeName}
              bikeDesc={bikeDesc}
            />
            <DialogContent>
              <StyledMainWidgetWrapper
                vertical={false}
                bordered
                {...restofProps}
              />
            </DialogContent>
          </Box>
        </StyledDialog>
      )}
    </>
  );
};

export { MainWidgetDialog };
