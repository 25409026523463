import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ClubMembership = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none">
    <path
      d="M1.84906 16.6059L11.9976 22.451L22.1462 16.6059V2.84906H1.84906V16.6059ZM11.9976 24.5886L0 17.6605V1H24V17.6605L11.9976 24.5886Z"
      fill="white"
    />
    <path
      d="M8.13884 9.10564C7.19303 8.98269 7.26396 8.68949 7.26396 8.68949L9.78928 8.25441C9.78928 8.25441 8.49352 9.15294 8.13884 9.10564ZM20.7087 13.3618C18.713 12.6572 15.7621 10.4061 15.7621 10.4061C16.3546 10.4073 16.9468 10.3742 17.5355 10.3068C16.5531 9.85784 15.6048 9.33784 14.6981 8.75097C13.4874 7.895 13.1942 7.24712 11.4397 7.03432C11.1388 6.99225 10.8356 6.96856 10.5317 6.96338C9.14234 6.97276 7.80148 7.47558 6.7485 8.3821C6.44111 8.64693 6.23303 8.79826 6.23303 8.79826C5.53327 8.84206 4.84354 8.98702 4.18534 9.2286C4.01322 9.27412 3.85304 9.35647 3.71586 9.46996C3.57869 9.58346 3.4678 9.72539 3.39086 9.88594C3.17332 10.3352 3.45707 11.508 3.46653 11.4986C5.27776 10.3825 8.49825 11.4986 8.71579 11.9147C8.41313 11.1108 7.18357 10.2832 7.18357 10.2832C7.18357 10.2832 9.0752 10.7135 9.5481 12.3876C10.021 14.0617 7.84091 14.7994 7.71322 14.8656C8.7727 14.7359 9.82303 14.5401 10.8581 14.2792C10.8432 14.52 10.8068 14.759 10.7493 14.9933C11.2222 14.7663 12.2957 14.2414 12.2957 14.2414C12.2957 14.2414 15.048 14.7616 16.1215 14.842C15.5224 14.4797 14.9805 14.0302 14.5136 13.5084C14.5136 13.5084 17.876 14.2556 19.0062 14.4542C18.4388 14.0791 17.9304 13.6217 17.4977 13.097C18.5529 13.2823 19.6232 13.3678 20.6945 13.3523"
      fill="white"
    />
  </SvgIcon>
);

export default ClubMembership;
