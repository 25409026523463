import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import PATHS from 'lib/navigation/paths';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import StyledBox from 'views/common/components/UI/PlanCard/PlanCard.styles';

export const PLAN_CARD_VARIANTS = {
  MINIMIZED: 'minimized',
  DEFAULT: 'default',
  OLD: undefined,
};
const PlanCard = (props) => {
  const {
    isPlanSelected,
    handlePlanSelect,
    price,
    title,
    subTitle,
    benefits,
    currentPlan,
    id,
    notMember,
    data,
    gifted,
    variant = PLAN_CARD_VARIANTS.OLD,
    yearlyPrice,
    className,
  } = props;
  const { t } = useTranslation('fe_er_plan_card');
  const appState = useSelector((state) => state.globalData.appState);
  const theme = useTheme();
  const [expanded, setExpanded] = useState('');
  const toggleAccordion = (panel) => {
    setExpanded(expanded === panel ? '' : panel);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const appNavigator = useAppNavigator();

  const getURLParam = () => {
    const urlParam = appNavigator.query.referral_code
      ? {
        membership_id: id,
        referral_code: appNavigator.query.referral_code,
      }
      : {
        membership_id: id,
      };

    if (gifted && yearlyPrice) {
      return {
        ...urlParam,
        gifted: 1,
        yearly: 1,
      };
    }
    if (yearlyPrice) {
      return {
        ...urlParam,
        yearly: 1,
      };
    }
    if (gifted) {
      return {
        ...urlParam,
        gifted: 1,
      };
    }
    return urlParam;
  };
  return (
    <StyledBox
      className={`${className} PCContainer ${
        notMember || gifted ? 'cursor_default' : ''
      } ${isPlanSelected === id && !notMember ? 'active' : ''} ${
        currentPlan ? 'current' : ''
      }`}
      onClick={!currentPlan && !gifted ? () => handlePlanSelect(data) : null}
      variant={variant}>
      <Box className="PCTitleAndBenfits">
        <Box>
          <Typography className="PCCardTitle" variant="h3" component="div">
            {title}
          </Typography>
          <Typography className="PCCardSubTitle" variant="h4" component="div">
            {subTitle}
          </Typography>
        </Box>

        <Typography className="PCPlanPrice" variant="body1" component="p">
          {price}
          {!gifted && (
            <Typography
              variant="body2"
              component="span"
              className="PCPlanPriceMonth">
              {t('fe_er_plan_card:per_month')}
            </Typography>
          )}
        </Typography>
        {variant !== PLAN_CARD_VARIANTS.MINIMIZED && yearlyPrice && (
          <Typography className="PCPrepaidNotice">
            {t('fe_er_plan_card:billed_as_prepaid', { price: yearlyPrice })}
          </Typography>
        )}
        {gifted && (
          <Typography
            variant="body2"
            className="PCTaxesText"
            dangerouslySetInnerHTML={{
              __html: t('fe_er_plan_card:include_taxes'),
            }}
          />
        )}

        {!isMobile && variant === PLAN_CARD_VARIANTS.DEFAULT && (
          <Box className="PCDevider" />
        )}

        <Box>
          {isMobile && variant === PLAN_CARD_VARIANTS.OLD && (
            <Button
              className="PCViewMore"
              expanded
              color="secondary"
              onClick={() => toggleAccordion(`panel${id.toString()}`)}>
              {t('fe_er_plan_card:view_all_benfit')}
              <ExpandMoreIcon />
            </Button>
          )}

          <Accordion
            className="PVAccordion"
            expanded={
              isMobile && variant === PLAN_CARD_VARIANTS.OLD
                ? expanded === `panel${id.toString()}`
                : true
            }>
            <AccordionSummary className="PVAccordionSummary" />
            <AccordionDetails className="PVAccordionDetails">
              {benefits.map((benefit) => (
                <Box className="PVABenfit" key={benefit.title}>
                  {variant !== PLAN_CARD_VARIANTS.MINIMIZED && (
                    <CheckIcon className="PVACheckMark" />
                  )}
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: benefit.title,
                    }}
                  />

                  {benefit.toolTip && (
                    <Tooltip title={benefit.toolTip} enterTouchDelay={0}>
                      <HelpOutlineIcon className="PVQuestionMark" />
                    </Tooltip>
                  )}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      {gifted && (
        <Button
          component={NavigatorLink}
          className="PVSelectPlanLink"
          href={PATHS.motorcycleClub.checkout({
            membership_id: id,
            gifted: 1,
          })}
          variant="contained"
          color="primary">
          {t('fe_er_plan_card:buy_now')}
        </Button>
      )}
      {(variant === PLAN_CARD_VARIANTS.MINIMIZED
        || variant === PLAN_CARD_VARIANTS.DEFAULT) && (
        <Button
          component={NavigatorLink}
          className="PVSelectPlanLink"
          href={
            appState.current_user
              ? PATHS.motorcycleClub.checkout(getURLParam())
              : PATHS.motorcycleClub.signUp(getURLParam())
          }
          variant={
            variant === PLAN_CARD_VARIANTS.MINIMIZED ? 'contained' : 'outlined'
          }
          color={
            variant === PLAN_CARD_VARIANTS.MINIMIZED ? 'primary' : 'secondary'
          }
          width="100%">
          {t('fe_er_plan_card:join_now')}
        </Button>
      )}
      {variant === PLAN_CARD_VARIANTS.MINIMIZED && yearlyPrice && (
        <Typography className="PCPrepaidNotice">
          {t('fe_er_plan_card:billed_as_prepaid', { price: yearlyPrice })}
        </Typography>
      )}
      {!gifted && variant === PLAN_CARD_VARIANTS.OLD && (
        <>
          {!notMember ? (
            <>
              <Button
                className="PVSelectBtn"
                variant="outlined"
                color="primary"
                startIcon={
                  isPlanSelected === id ? (
                    <CheckCircleIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }>
                {isPlanSelected === id
                  ? t('fe_er_plan_card:selected')
                  : t('fe_er_plan_card:select_plan')}
              </Button>
            </>
          ) : (
            <Button
              className="PVSelectPlanLink"
              component={NavigatorLink}
              href={PATHS.motorcycleClub.checkout({ membership_id: id })}
              variant="contained"
              color="primary">
              {t('fe_er_plan_card:select_plan')}
            </Button>
          )}
        </>
      )}
    </StyledBox>
  );
};

export { PlanCard };
