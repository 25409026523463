import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AIChatIcon = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M15.7692 7.21896C15.5809 7.21896 15.4204 7.15264 15.2877 7.02C15.1551 6.88736 15.0888 6.72686 15.0888 6.5385C15.0888 6.35014 15.1551 6.18964 15.2877 6.057C15.4204 5.92436 15.5809 5.85804 15.7692 5.85804C15.9576 5.85804 16.1181 5.92436 16.2507 6.057C16.3834 6.18964 16.4497 6.35014 16.4497 6.5385C16.4497 6.72686 16.3834 6.88736 16.2507 7.02C16.1181 7.15264 15.9576 7.21896 15.7692 7.21896ZM12.6923 7.21896C12.504 7.21896 12.3435 7.15264 12.2108 7.02C12.0782 6.88736 12.0119 6.72686 12.0119 6.5385C12.0119 6.35014 12.0782 6.18964 12.2108 6.057C12.3435 5.92436 12.504 5.85804 12.6923 5.85804C12.8807 5.85804 13.0412 5.92436 13.1738 6.057C13.3065 6.18964 13.3728 6.35014 13.3728 6.5385C13.3728 6.72686 13.3065 6.88736 13.1738 7.02C13.0412 7.15264 12.8807 7.21896 12.6923 7.21896ZM9.6154 7.21896C9.42704 7.21896 9.26654 7.15264 9.1339 7.02C9.00126 6.88736 8.93494 6.72686 8.93494 6.5385C8.93494 6.35014 9.00126 6.18964 9.1339 6.057C9.26654 5.92436 9.42704 5.85804 9.6154 5.85804C9.80376 5.85804 9.96426 5.92436 10.0969 6.057C10.2295 6.18964 10.2959 6.35014 10.2959 6.5385C10.2959 6.72686 10.2295 6.88736 10.0969 7.02C9.96426 7.15264 9.80376 7.21896 9.6154 7.21896ZM20 15.0296V2.15983C20 1.77126 19.8654 1.44236 19.5962 1.17313C19.3269 0.903903 18.998 0.769287 18.6095 0.769287H6.77519C6.38663 0.769287 6.05773 0.903903 5.7885 1.17313C5.51927 1.44236 5.38465 1.77126 5.38465 2.15983V10.9172C5.38465 11.3057 5.51927 11.6346 5.7885 11.9039C6.05773 12.1731 6.38663 12.3077 6.77519 12.3077H17.2781L20 15.0296Z"
      fill="#EDC14F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0001 13.8462V14.3787C15.0001 14.7673 14.8655 15.0962 14.5963 15.3654C14.327 15.6346 13.9981 15.7693 13.6096 15.7693H3.10665L0.384766 18.4911V5.62137C0.384766 5.23281 0.519381 4.90391 0.788612 4.63468C1.05784 4.36545 1.38674 4.23083 1.7753 4.23083H3.84629V5.38466H1.7753C1.71612 5.38466 1.66187 5.40932 1.61255 5.45862C1.56325 5.50794 1.53859 5.56219 1.53859 5.62137V15.6805L2.61554 14.6154H13.6096C13.6688 14.6154 13.723 14.5908 13.7723 14.5415C13.8216 14.4921 13.8463 14.4379 13.8463 14.3787V13.8462H15.0001ZM5.38476 4.23083H13.6096C13.9981 4.23083 14.327 4.36545 14.5963 4.63468C14.8655 4.90391 15.0001 5.23281 15.0001 5.62137V12.3077H13.8463V5.62137C13.8463 5.56219 13.8216 5.50794 13.7723 5.45862C13.723 5.40932 13.6688 5.38466 13.6096 5.38466H5.38476V4.23083Z"
      fill="#EDC14F"
    />
  </SvgIcon>
);

export default AIChatIcon;
