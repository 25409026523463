import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FloatingButtonWithFloatingDialog } from 'views/common/components/UI/FloatingButtonWithFloatingDialog';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[100]}`,
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
  '& .RADRewardLogo': {
    width: theme.typography.pxToRem(274),
    height: 'auto',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(28.125),
      marginBottom: theme.spacing(2),
    },
  },
  '& .RTextContainer': {
    flex: '1 0 0',
    color: theme.palette.grey[700],
    width: theme.spacing(98.875),
    [theme.breakpoints.down('xl')]: {
      width: theme.spacing(58.875),
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },

  '& .RNonloginedText': {
    fontSize: theme.typography.pxToRem(28),
    maxWidth: theme.spacing(82),
    lineHeight: theme.typography.pxToRem(40),
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(30),
    },
  },
  '& .RBtnsContainer ': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },

  '& .RBtn': {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    '&.RReservationBtn': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '& .RMoreBtn': {
    padding: theme.spacing(0, 1),
    textTransform: 'capitalize',
    verticalAlign: 'baseline',
  },
  '& .RASMoreBtn': {
    color: theme.palette.secondary.light,
    fontWeight: 500,
    textTransform: 'capitalize',
    paddingBottom: 0,
    textDecoration: 'underline',
  },
}));

export const StyledFloatingButtonWithFloatingDialog = styled(
  FloatingButtonWithFloatingDialog,
)(({ theme }) => ({
  '&.staticReservationFloatingButton': {
    width: theme.spacing(26.25),
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      margin: theme.spacing(2, 0),
    },
  },
  '& .reservationLable': {
    width: `calc(100% - ${theme.spacing(1.75)})`,
    position: 'absolute',
    left: 0,
  },
  '& .RFBPlusButton': {
    position: 'absolute !important',
    top: `${theme.spacing(-2.6)} !important`,
    right: '0 !important',
  },
}));

export default StyledBox;
