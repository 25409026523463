import React from 'react';
import { Box } from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import {
  StyledBox,
  StyledImgBox,
} from 'views/common/components/UI/TourPackageNewVersion/TourPackageNewVersion.styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { useTranslation } from 'next-i18next';
import { Typography700 } from 'views/common/components';

const TourPackageNewVersion = (props) => {
  const {
    title, BGImage, icon, subTitle, path,
  } = props;
  const { t } = useTranslation('fe_er_tours_package_new_version_page');
  return (
    <NavigatorLink href={path}>
      <StyledBox className="TourPackage" BGImage={BGImage}>
        <StyledImgBox
          imgHeight="100%"
          image={{
            src: BGImage,
            alt: t(
              'fe_er_tours_package_new_version_page:motorcycle_rental_and_tour_packages',
            ),
          }}
        />
        <Box className="textContainer">
          {icon}
          <Typography700 variant="body1" component="p" className="TPTitle">
            {title}
          </Typography700>
          <Typography500 variant="h5" component="p" className="TPSubTitle">
            {subTitle}
          </Typography500>
        </Box>
        <Box className="overlay" />
      </StyledBox>
    </NavigatorLink>
  );
};
export { TourPackageNewVersion };
