import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import StyledBox, {
  StyledImgBox,
} from 'views/common/components/UI/AppAd/AppAd.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PATHS from 'lib/navigation/paths';

const AppAd = (props) => {
  const {
    title, desc, className, xlCol = 7, titleVariant = 'h3',
  } = props;
  const { t } = useTranslation('fe_er_common_app_ad');
  const appleStore = {
    src: '/imgs/appAD/AppStore.png',
    alt: 'Download on the App Store',
  };
  const googlePlay = {
    src: '/imgs/appAD/GooglePlay.png',
    alt: 'Get it on Google Play',
  };
  const QRCode = {
    src: '/imgs/appAD/QRCode.png',
    alt: 'Eaglerider QR Code',
  };
  return (
    <StyledBox className={className}>
      <Grid container className="AAAppAD">
        <Grid item xl={xlCol} lg={9}>
          <Typography500
            component="h3"
            variant={titleVariant}
            pb={2}
            className="AATitle">
            {title}
          </Typography500>
          {desc && (
            <Typography component="h4" pb={4.5} className="AAdesc">
              {desc}
            </Typography>
          )}
          <Box className="AAdownloadBtns">
            <NavigatorLink href={PATHS.app.ios}>
              <StyledImgBox
                className="AADownload"
                imgHeight="3.25rem"
                imgWidth="11.25rem"
                image={appleStore}
                objectFit="contain"
              />
            </NavigatorLink>

            <NavigatorLink pl={1.25} href={PATHS.app.playStore}>
              <StyledImgBox
                className="AADownload"
                imgHeight="3.25rem"
                imgWidth="11.25rem"
                image={googlePlay}
                objectFit="contain"
              />
            </NavigatorLink>
          </Box>
        </Grid>
        <Grid item>
          <Box className="AAScan">
            <Typography500 component="h5" variant="h5" className="AAScanText">
              <SmartphoneIcon />
              {t('fe_er_common_app_ad:scan_phone')}
            </Typography500>
          </Box>
          <StyledImgBox
            className="AAQRCode"
            imgHeight="10rem"
            imgWidth="10rem"
            image={QRCode}
            objectFit="contain"
          />
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export { AppAd };
