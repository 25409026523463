import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { HorizontalStepperSmall, ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .MODialogTitle': {
    position: 'relative',
    height: theme.spacing(18),
    padding: 0,
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(12),
    },
  },
  '& .MOBgHeader': {
    height: theme.spacing(18),
    overflow: 'hidden',
    zIndex: -1,
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(12),
    },
  },
  '& .MOStepTitle': {
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  '& .MODialogText': {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    textAlign: 'center',
    color: 'white',
    fontWeight: 500,
    fontFamily: theme.typography.fontFamilySecondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  '& .MOCloseIconButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.fogWhite,
    [theme.breakpoints.down('md')]: {
      right: 0,
      top: 0,
    },
    '& .MOCloseIcon': {
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(30),
      },
    },
  },
  '& .MOProgressBg': {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    color: theme.palette.common.fogWhite,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
      display: 'block',
      textAlign: 'center',
    },
    '& .MOProgressWrapper': {
      minWidth: theme.spacing(16),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: theme.spacing(16),
      },
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(12),
    },
  },
}));

export const StyledHorizontalStepperSmall = styled(HorizontalStepperSmall)(
  ({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(17),
      margin: '0 auto',
    },
    '& .MOHorizontalStepper': {
      minWidth: theme.spacing(4),

      '& .stepIcon': {
        border: 0,
        marginBottom: 0,
        backgroundColor: theme.palette.grey['100'],
        color: theme.palette.secondary.main,
      },
      '& .stepDoneIcon': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
      },
      '&.step': {
        backgroundColor: 'transparent',
        '&.stepActive': {
          '& .stepIcon': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            lineHeight: 'initial',
          },
          '&::before': {
            backgroundColor: theme.palette.grey['100'],
          },
        },
        '&.stepDone': {
          '&::before': {
            backgroundColor: theme.palette.grey['100'],
          },
        },
      },
    },
  }),
);

export default StyledBox;
