import React, { useState } from 'react';
import StyledBox, {
  StyledFloatingButtonWithFloatingDialog,
} from 'views/common/components/UI/Reward/Reward.styles';
import { Box, Button } from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { useTranslation } from 'next-i18next';
import PATHS from 'lib/navigation/paths';
import RewardProgramLogoBlack from 'views/common/components/UI/Icons/rewards/RewardProgramLogoBlack';

const Reward = (props) => {
  const { t } = useTranslation('fe_er_common_reward');
  const { offValue, loggedInUser = false } = props;
  const [openMainWidgetDialog, setOpenMainWidgetDialog] = useState(false);
  const handleOpenMainWidgetDialog = () => {
    setOpenMainWidgetDialog(true);
  };
  const handleCloseMainWidgetDialog = () => {
    setOpenMainWidgetDialog(false);
  };
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const floating = false;
  return (
    <StyledBox>
      <Box className="RTextContainer">
        <NavigatorLink href={PATHS.rewards()} target="_blank">
          <RewardProgramLogoBlack className="RADRewardLogo" />
        </NavigatorLink>
        {loggedInUser ? (
          <Typography500 component="p" className="RNonloginedText">
            {t('fe_er_common_reward:getting_off_every_rental', {
              value: offValue,
            })}
          </Typography500>
        ) : (
          <Typography500 component="p" className="RNonloginedText">
            {t('fe_er_common_reward:sign_in_togetting_off_every_rental', {
              value: offValue,
            })}
            <Button
              component={NavigatorLink}
              href={PATHS.rewards()}
              variant="text"
              className="RASMoreBtn">
              {t('fe_er_common_reward:learn_more')}
            </Button>
          </Typography500>
        )}
      </Box>
      <Box className="RBtnsContainer">
        {loggedInUser ? (
          <>
            <Button
              variant="outlined"
              color="secondary"
              className="RBtn"
              component={NavigatorLink}
              href={PATHS.user.rewards()}>
              {t('fe_er_common_reward:view_your_rewards')}
            </Button>
            {floating ? (
              <StyledFloatingButtonWithFloatingDialog
                floatingDialog
                scrollValue={-1}
                staticButton
                text={t('fe_er_common_reward:start_reservation')}
                handleFloatingPointClick={handleOpenMainWidgetDialog}
                widgetDialogData={{ open: openMainWidgetDialog }}
                handleWidgetDialogClose={handleCloseMainWidgetDialog}
              />
            ) : (
              <Button
                variant="contained"
                className="RBtn RReservationBtn"
                onClick={handleScrollToTop}>
                {t('fe_er_common_reward:start_reservation')}
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              className="RBtn"
              color="secondary"
              component={NavigatorLink}
              href={PATHS.users.signIn({ secure: 'true' })}>
              {t('fe_er_common_reward:sign_in')}
            </Button>
            <Button
              className="RBtn"
              variant="contained"
              component={NavigatorLink}
              href={PATHS.users.signUp({ secure: 'true' })}>
              {t('fe_er_common_reward:sign_up')}
            </Button>
          </>
        )}
      </Box>
    </StyledBox>
  );
};
export { Reward };
