import { styled } from '@mui/material/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ImgBox } from 'views/common/components/UI/ImgBox';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(() => ({
  '&.MCContainer': {
    position: 'relative',
  },
}));

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  '&.carousel-container': {
    position: 'unset',
    '& .react-multi-carousel-track ': {
      '& .react-multi-carousel-item': {
        '& .MTSMakeCarouselItem': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: theme.spacing(0.25, 0.75),
          height: theme.spacing(14),
          overflow: 'visible',
          borderRadius: theme.spacing(0.5),
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.common.fogWhite}`,
          cursor: 'pointer',
          '&.active': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },

        '&:first-child': {
          '& .MTSMakeCarouselItem': {
            margin: theme.spacing(0.25, 0.75, 0.25, 0.15),
          },
        },
        '&:last-child': {
          '& .MTSMakeCarouselItem': {
            margin: theme.spacing(0.25, 0.15, 0.25, 0.75),
          },
        },
        '&:only-child': {
          '& .MTSMakeCarouselItem': {
            margin: theme.spacing(0.25, 0.375, 0.25, 0.15),
          },
        },
      },
    },
  },
  '& .react-multiple-carousel__arrow': {
    minWidth: 'unset',
    minHeight: 'unset',
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[50]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&::before': {
      color: theme.palette.grey[300],
      fontSize: theme.spacing(1.75),
      padding: 0,
      content: '"\\e83b"',
      fontWeight: 500,
    },
    '&.react-multiple-carousel__arrow--right': {
      right: theme.spacing(-1),
      transform: 'rotate(270deg)',
    },
    '&.react-multiple-carousel__arrow--left': {
      left: theme.spacing(-1),
      transform: 'rotate(90deg)',
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(10),
    },
  },
}));

export default StyledBox;
