import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FloatingLocationInfoContent } from 'views/common/components';
import { useTranslation } from 'next-i18next';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import PATHS from 'lib/navigation/paths';
import Typography500 from 'views/common/components/UI/Typography500';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '&.dialogFloatingLocation': {
    '&.MuiDialog-root': {
      position: 'absolute',
      bottom: theme.typography.pxToRem(144),
      left: theme.typography.pxToRem(14),
      zIndex: 1100,
      [theme.breakpoints.down('lg')]: {
        bottom: theme.spacing(-11.4),
      },
    },

    '& .MuiDialog-paper': {
      borderRadius: '4px',
      backgroundColor: theme.palette.grey[500],
      overflowY: 'unset',
      width: theme.spacing(52.125),
      [theme.breakpoints.down('lg')]: {
        width: theme.spacing(36.25),
      },
    },
    '& .MuiDialogContent-root': {
      paddingBottom: '0px',
      padding: theme.spacing(0, 2),
    },

    '& .MuiDialogActions-root': {
      padding: theme.spacing(0, 2, 1),
    },

    '& .bookbtn': {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },

    '& .btnPos': {
      marginLeft: 'auto',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },

    '& .locDetails': {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    '& .FLILocationDetailsBtn': {
      color: theme.palette.primary.main,
    },
    '& .locbtn': {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: '700',
      letterSpacing: 0.105,
      paddingRight: theme.spacing(0.25),
      textTransform: 'uppercase',
      textDecoration: 'underline',
      borderRadius: '0 !important',
    },

    '& .imgsize': {
      width: theme.typography.pxToRem(50.75),
      height: theme.typography.pxToRem(49.88),
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(4.25),
        height: theme.spacing(3.15),
      },
    },

    '& .dialog': {
      color: theme.palette.common.fogWhite,
      '&.dialogHeader': {
        padding: theme.spacing(2, 2, 1.5),
      },
      '& a': {
        color: theme.palette.common.fogWhite,
      },
    },

    '& .infoContent': {
      '& a': {
        textDecorationColor: theme.palette.common.fogWhite,
      },
    },

    '& .tri': {
      height: '0',
      width: '0',
      position: 'absolute',
      bottom: theme.spacing(-1.625),
      left: '50%',
      borderTop: `${theme.spacing(1.625)} solid ${theme.palette.grey[500]}`,
      borderLeft: `${theme.spacing(1.125)} solid transparent`,
      borderRight: `${theme.spacing(1.125)} solid transparent`,
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },

    '& .closeIcon': {
      fill: theme.palette.common.fogWhite,
    },
    '& .FLITitleGrid': {
      flexWrap: 'nowrap',
    },
    '& .FLITextGrid': {
      paddingRight: theme.spacing(2),
    },
    '& .FLILocationText': {
      fontFamily: 'DrukText',
      fontWeight: 700,
      letterSpacing: '.12px',
    },
    '& .FLIERBadge': {
      color: theme.palette.primary.dark,
      fontSize: theme.spacing(1.25),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.primary.A000,
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(0.5),
      whiteSpace: 'nowrap',
      position: 'relative',
      top: theme.spacing(-0.25),
    },
  },
}));
// need more fe work
const FloatingLocationInfo = (props) => {
  const { t } = useTranslation('fe_er_common_floating_location_info');
  const {
    name,
    address,
    phones,
    operationHours,
    locationSlug,
    handleClose,
    open,
    handleBook,
    className,
    isClubMember = false,
  } = props;

  return (
    <>
      <StyledDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
        disableRestoreFocus
        maxWidth="xs"
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        container={() => document.getElementById('mapContainer')}
        className={`dialogFloatingLocation ${className}`}>
        <span className="tri" />
        <DialogTitle
          id="customized-dialog-title"
          className="dialog dialogHeader"
          onClose={handleClose}>
          <Grid container className="FLITitleGrid" spacing={2}>
            <Grid item>
              <img
                src="/imgs/logo-badge.svg"
                alt="logo.png"
                className="imgsize"
              />
            </Grid>
            <Grid item className="FLITextGrid">
              <Typography500
                component="span"
                variant="h5"
                className="FLILocationText"
                mr={1}>
                {name}
              </Typography500>
              {isClubMember && (
                <Typography component="span" className="FLIERBadge">
                  {t('fe_er_common_floating_location_info:club_eaglerider')}
                </Typography>
              )}
            </Grid>
          </Grid>
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon className="closeIcon" />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className="dialog infoContent">
          <FloatingLocationInfoContent
            className="dialog"
            address={address}
            phones={phones}
            operationHours={operationHours}
          />
        </DialogContent>
        <DialogActions className="dialog">
          <Grid container alignItems="center">
            {locationSlug && (
              <Grid item>
                <NavigatorLink
                  href={PATHS.locationSlug(locationSlug)}
                  className="locDetails">
                  <Button
                    label="Location Details"
                    onClick={handleClose}
                    color="primary"
                    className="FLILocationDetailsBtn"
                    size="large">
                    <Typography className="locbtn">
                      {t(
                        'fe_er_common_floating_location_info:location_details',
                      )}
                    </Typography>
                    <OpenInNewIcon />
                  </Button>
                </NavigatorLink>
              </Grid>
            )}
            {handleBook && (
              <Grid item className="btnPos">
                <Button
                  onClick={handleBook}
                  color="primary"
                  variant="contained"
                  className="bookbtn">
                  {t('fe_er_common_floating_location_info:book_now')}
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </StyledDialog>
    </>
  );
};
export { FloatingLocationInfo };
