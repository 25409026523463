import React from 'react';
import {
  CardContent,
  Grid,
  Box,
  Typography,
  IconButton,
  CardActions,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Location from 'views/common/components/UI/Icons/Location';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'next-i18next';
import StyledBikeCard from 'views/common/components/UI/BikeCardV3/BikeCardV3.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import { Typography700, ImgBox } from 'views/common/components';
import EagleRiderClubGrayscale from 'views/common/components/UI/Icons/EagleRiderClubGrayscale';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ERPlaceholder from 'public/imgs/default/er_default.png';

// import { RibbonChip } from 'views/common/components/UI/RibbonChip/RibbonChip';

const BikeCardV3 = (props) => {
  const { t } = useTranslation('fe_er_common_bike_card');
  const {
    image,
    price,
    soldout,
    hourlyPrice,
    tentative,
    valueDeal,
    vehicleModel,
    vehicleMake,
    region,
    owner,
    credit,
    url,
    availableCount,
    onClick,
    priceInCredits,
    specsPath = null,
    noMotorcycleLicense,
    handleMembershipOverlayOpen,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const showMembershipPromotion = useSelector(
    (state) => state.globalData.showMembership,
  );
  const appState = useSelector((state) => state.globalData.appState);

  return (
    <StyledBikeCard
      className="BCcard"
      soldout={soldout}
      credit={credit}
      isMember={appState.current_user?.is_member}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <NavigatorLink
        className="BClinkStyle"
        onClick={onClick}
        {...(url ? { href: url } : {})}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          pt={1.25}
          mb={1}
          className="BCHeader">
          <Box
            className="BCHeaderLabels"
            display="flex"
            alignItems="flex-start"
            flexDirection="column">
            {valueDeal && (
              <Box
                display="flex"
                alignItems="center"
                className="BCvalueDeal BCHeaderItem textTransformUppercase">
                <Typography500
                  className="BCfontsmall"
                  component="span"
                  variant="h6">
                  {t('fe_er_common_bike_card:value_deal')}
                </Typography500>
              </Box>
            )}

            {tentative && (
              <Accordion
                className={`BCAccordion ${
                  valueDeal ? 'BCAccordionWithValue' : ''
                }`}
                onClick={(e) => e.stopPropagation()}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="BCExpandIcon" />}
                  className="BCAccordionSummary textTransformUppercase">
                  <Typography500
                    className="BCfontsmall"
                    component="span"
                    variant="h6">
                    {`${t('fe_er_common_bike_card:limited_availability')} !`}
                  </Typography500>
                </AccordionSummary>
                <AccordionDetails className="BCAccordionDetails">
                  <Divider />
                  <Typography className="BCfontsmall" component="span">
                    {t('fe_er_common_bike_card:limited_availability_details')}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}

            {!!availableCount && (
              <Box
                display="flex"
                alignItems="center"
                className="BCOnlyLeft BCHeaderItem textTransformUppercase">
                <Typography500
                  className="BCfontsmall"
                  component="span"
                  variant="h6">
                  {`${t('fe_er_common_bike_card:availability', {
                    count: availableCount,
                  })}`}
                </Typography500>
              </Box>
            )}

            {soldout && (
              <Box
                display="flex"
                alignItems="center"
                className="BCsoldout BCHeaderItem textTransformUppercase">
                <ErrorOutlineIcon />
                <Typography500
                  className="BCfontsmall"
                  component="span"
                  variant="h6">
                  {t('fe_er_common_bike_card:soldout')}
                </Typography500>
              </Box>
            )}

            {noMotorcycleLicense && (
              <Box
                display="flex"
                alignItems="center"
                className="noMotorcycleLicense textTransformUppercase">
                <Typography500 component="span" variant="h6">
                  {t('fe_er_common_bike_card:noMotorcycleLicense')}
                </Typography500>
              </Box>
            )}
          </Box>

          {specsPath && (
            <IconButton
              className="BCBikeSpecs"
              onClick={(e) => e.stopPropagation()}>
              <InfoOutlinedIcon className="BCBikeSpecsIcon" />
              <Typography500
                href={specsPath}
                translate={false}
                target="_blank"
                component={NavigatorLink}
                className="BCBikeSpecsText BCSmallLineHeight">
                {t('fe_er_common_bike_card:view_bike_specs')}
              </Typography500>
            </IconButton>
          )}
        </Box>

        <ImgBox
          image={{
            src: image.src || ERPlaceholder,
            alt: image.alt,
          }}
          priority={image.priority}
          imgHeight="160px"
          className="BCImg"
        />
        <CardContent className="BCcontent">
          <Box
            display="flex"
            justifyContent="space-between"
            {...(credit
              ? { align: 'center', spacing: '0', className: 'BCContentCont' }
              : { spacing: '2' })}>
            <Box item {...(credit ? { sm: '12', className: 'BCContent' } : {})}>
              <Box className="BCMakeModel">
                <Typography
                  variant="subtitle2"
                  component="p"
                  className="BCfontsmall">
                  {vehicleMake.name}
                </Typography>
                <Typography700 variant="h4" component="p" className="BCmodal">
                  {vehicleModel.name}
                </Typography700>
              </Box>
            </Box>
          </Box>

          {owner && (
            <Box className="BCLocation" mt={1}>
              {region?.name && (
                <Box>
                  <Location />
                  <Typography component="span" className="BCSmallLineHeight">
                    {region.name}
                  </Typography>
                </Box>
              )}
              <Box>
                <EagleRiderClubGrayscale />
                <Typography component="span" className="BCOwnerName">
                  {owner.display_name}
                </Typography>
              </Box>
            </Box>
          )}
        </CardContent>

        <Divider light />

        <CardActions className="BCCardActions">
          <Grid container columnSpacing={1.5} flexWrap="nowrap">
            {!credit && (
              <Grid
                item
                sm={priceInCredits && showMembershipPromotion ? 6 : 12}>
                <Box className="BCnDailyPrice">
                  <Typography500
                    component="span"
                    className="textTransformUppercase BCnDailyPriceText">
                    {t('fe_er_common_bike_card:price')}
                  </Typography500>

                  <Box>
                    <Typography700 component="span" className="BCPrice">
                      {price || hourlyPrice}
                    </Typography700>
                    <Typography component="span" className="BCSmallLineHeight">
                      /
                      {price
                        ? t('fe_er_common_bike_card:day')
                        : t('fe_er_common_bike_card:hour')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}

            {priceInCredits && showMembershipPromotion && (
              <>
                <Divider orientation="vertical" light flexItem />
                <Grid item sm={6}>
                  <Box className="BCClubPriceWrapper">
                    <Box onClick={handleMembershipOverlayOpen}>
                      <Typography500
                        component="span"
                        color="primary"
                        className="textTransformUppercase BCnDailyPriceText BCMembershipPrice">
                        {t('fe_er_common_bike_card:membership_price')}
                      </Typography500>
                      <Box>
                        <Typography700
                          color="primary"
                          variant="subtitle1"
                          className="BCCreditPrice">
                          {priceInCredits}
                          {appState.current_user?.is_member && (
                            <>
                              {' '}
                              {priceInCredits > 1
                                ? t('fe_er_common_bike_card:credits.many')
                                : t('fe_er_common_bike_card:credits.one')}
                            </>
                          )}
                          <Typography
                            color="primary"
                            component="span"
                            className="BCSmallLineHeight">
                            /
                            {t('fe_er_common_bike_card:day')}
                          </Typography>
                        </Typography700>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </CardActions>
      </NavigatorLink>
    </StyledBikeCard>
  );
};

export { BikeCardV3 };
