import { Box, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChatBotWrapper } from 'views/common/components/Logical/ChatBotWrapper';

const scaleToOne = keyframes`
0% {
  transform: scaleX(0);
  opacity:0;
}
50% {
  transform: scaleX(0);
  opacity:1;
}
100% {
  transform: scaleX(1);
  opacity:1;
}
`;

const StyledBox = styled(Box)(({ theme, openChat, widgetPage }) => ({
  '&.CVFDContainer': {
    position: 'fixed',
    bottom: theme.spacing(2.5),
    right: theme.spacing(2.5),
    zIndex: 1100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      right: theme.spacing(1),
      left: openChat ? theme.spacing(1) : 'unset',
      bottom: widgetPage && theme.spacing(14),
      top: !openChat ? 'unset' : theme.spacing(8),
      justifyContent: 'flex-end',
    },
  },
  '& .CVFDSlide': {
    display: !openChat && 'none',
  },
  '& .CVFDAlertContainer': {
    position: 'relative',
    width: theme.spacing(37.5),
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2, 2, 2),
    border: '.5px solid',
    borderColor: theme.palette.grey[100],
    borderRadius: 6,
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.24)',
    animation: `${scaleToOne} .5s`,
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
  },
  '& .CVFDAlertIconContainer': {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50% , -50%)',

    padding: theme.spacing(2),
    '& svg': {
      width: theme.spacing(6.25),
      height: theme.spacing(6.25),
      border: '.5px solid',
      borderColor: theme.palette.grey[100],
      borderRadius: '50%',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
    },
  },
  '& .CBFDIconBtn': {
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1, 2),
    borderRadius: theme.typography.pxToRem(64.103),
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
    color: theme.palette.common.fogWhite,
    transition: 'all 0.2s ease-out',
    '&.CBFDOpenIconBtn': {
      borderRadius: '50%',
      padding: theme.spacing(1),
      transition: 'all 0.2s ease-in',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& svg': {
      width: theme.typography.pxToRem(28),
      height: theme.typography.pxToRem(28),
      '&.CBDChatIcon': {
        width: theme.typography.pxToRem(20),
        height: theme.typography.pxToRem(18.462),
      },
      '& path': {
        fill: theme.palette.common.fogWhite,
      },
    },
  },
  '& .CBFBDSignBtn': {
    fontWeight: 700,
    textDecoration: 'underline',
    color: theme.palette.secondary.light,
  },
  '& .CBWelcomeText': {
    color: theme.palette.common.black,
  },
}));

export const StyledChatBotWrapper = styled(ChatBotWrapper)(({ theme }) => ({
  width: theme.spacing(45),
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 7,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .CVHeader': {
    position: 'static',
    borderRadius: '7px 7px 0px 0px',
  },
  '& .CVBody': {
    position: 'static',
    height: theme.spacing(55),
    overflow: 'scroll',
    [theme.breakpoints.down('md')]: {
      flex: '1 1 0',
      height: 'unset',
    },
  },
  '& .CVFooter': {
    position: 'static',
    borderRadius: '0px 0px 7px 7px',
  },
}));

export default StyledBox;
