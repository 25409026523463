import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme, selectedTab }) => ({
  color: theme.palette.secondary.dark,
  '& .MOSOUppercase': {
    textTransform: 'uppercase',
  },
  '& .MOSORelative': {
    position: 'relative',
  },
  '& .MOSOmonthCount': {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[400],
  },
  '& .MOSOThFlex': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .MOSOJoinNow': {
    fontSize: theme.typography.pxToRem(18),
  },
  '& .MOSOLimitedTime': {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    color: theme.palette.common.fogWhite,
    backgroundColor: theme.palette.error.dark,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down('lg')]: {
      position: 'initial',
      padding: theme.typography.pxToRem(4),
    },
  },
  '& .MOSOPrices': {
    paddingTop: theme.typography.pxToRem(8),

    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.typography.pxToRem(24),
    },
  },
  '& .MOSOPlanPrice': {
    fontSize: theme.typography.pxToRem(28),
  },
  '& .MOSOOldPrice': {
    color: theme.palette.grey[300],
    textDecoration: 'line-through',
    paddingLeft: theme.spacing(1),
  },
  '& .MOSONewPrice': {
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(21),
    },
  },
  '& .mosorowheader': {
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.typography.pxToRem(20),
    },
  },
  '& .MOSOSecMain': {
    color: theme.palette.secondary.main,
  },
  '& .MOSOSecLight': {
    color: theme.palette.secondary.light,
  },
  '& .MOSOStrikeThrough': {
    textDecoration: 'line-through',
  },
  '& .MOSOCopyIcon': {
    '& svg': {
      verticalAlign: 'sub',
      marginLeft: theme.spacing(1),
    },
  },
  '& .MOSOHelpIcon': {
    padding: '0',
  },
  '& .MOSOComparePlan': {
    fontFamily: theme.typography.fontFamilySecondary,
  },
  '& .MuiTab-root': {
    fontWeight: '700',
    fontSize: theme.typography.pxToRem(24),
  },
  '& .MOSOTable': {
    overflow: 'hidden',
    '& .MOSOHeader': {
      position: 'relative',
      textAlign: 'center',
      textTransform: 'initial',
      fontWeight: 'initial',
      [theme.breakpoints.down('lg')]: {
        border: '0',
      },
    },
    '& .CSHiddencell': {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    '& .MOSOshowTablet': {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },

    '& .MuiTableBody-root .MuiTableRow-root:nth-child(5) .MuiTableCell-root': {
      [theme.breakpoints.down('lg')]: {
        border: '0',
      },
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-child(1) .MuiTableCell-root': {
      [theme.breakpoints.up('lg')]: {
        borderTop: '0',
      },
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      [theme.breakpoints.up('lg')]: {
        borderBottom: '0',
      },
    },

    '& th:first-child': {
      minWidth: theme.typography.pxToRem(350),
      [theme.breakpoints.down('lg')]: {
        minWidth: theme.typography.pxToRem(222),
        backgroundColor: selectedTab == 2 && theme.palette.primary.A000,
      },
    },
    '& th,& td': {
      [theme.breakpoints.up('lg')]: {
        border: `1px solid ${theme.palette.grey[100]}`,
      },
    },
    '& .MuiTableBody-root td': {
      textAlign: 'center',
    },
    '& .MuiTableRow-root': {
      [theme.breakpoints.up('lg')]: {
        verticalAlign: 'top',
      },
    },
    '& tbody [class*="MuiTableRow-root"]:nth-of-type(odd)': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiTableCell-root': {
      padding: `${theme.spacing(2)} !important`,
    },
    '& .MOSOBestValue': {
      right: theme.typography.pxToRem(-32),
      top: theme.typography.pxToRem(33),
      position: 'absolute',
      backgroundColor: theme.palette.primary.main,
      width: theme.typography.pxToRem(157),
      transform: 'rotate(45deg)',
      color: theme.palette.common.fogWhite,
    },
    '& .MOSOPromoted': {
      backgroundColor: theme.palette.primary.A000,
    },
  },
  '& .MOSORewardsLogoContainer': {
    height: theme.typography.pxToRem(11),
    width: theme.typography.pxToRem(141),
    marginTop: theme.spacing(1),
  },
}));
export default StyledBox;
