import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .RPTable': {
    minWidth: theme.typography.pxToRem(976),
    '& tbody .MuiTableRow-root': {
      '&:nth-of-type(odd):not(.tfoot),&:nth-of-type(even):not(.tfoot)': {
        backgroundColor: 'white',
      },
    },
  },
  '& .orange': {
    color: theme.palette.primary.main,
  },
  '& .MuiTableCell-root': {
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    maxWidth: theme.typography.pxToRem(383),
    padding: theme.spacing(1),
    color: theme.palette.grey[900],
  },
  '& .RPTRowTh': {
    color: theme.palette.common.fogWhite,
    backgroundColor: theme.palette.info.dark,
  },

  '& .MuiTableHead-root': {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.grey.A000,
  },
  '& .DWTLink': {
    color: `${theme.palette.common.fogWhite} !important`,
    textTransform: 'uppercase',
    fontWeight: '500 !important',
    textDecoration: 'underline !important',
  },
}));

export default StyledBox;
